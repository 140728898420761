














































import Vue, { PropOptions } from 'vue';

import PageScroll from '~/libs/PageScroll';
import { NOOP } from '~/utils';

export default Vue.extend({
  name: 'TypeSwitch',

  props: {
    list: {
      type: Array,
      required: true,
    } as PropOptions<Array<TypeListItem>>,

    title: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },

  data: (): {
    isOpenActionSheet: boolean,
  } => ({
    isOpenActionSheet: false,
  }),

  computed: {
    routePath (): string {
      return this.$route.path;
    },

    activeItem (): TypeListItem | undefined {
      return this.list.find((item) => {
        let isActive: boolean = false;

        const routePathWithoutSlash: string = this.routePath.replace('/', '');
        item.activeValues.forEach((itemPath) => {
          const itemActivePathWithoutSlash: string = itemPath.replace('/', '');
          if (itemActivePathWithoutSlash === routePathWithoutSlash) {
            isActive = true;
          }
        });

        return isActive;
      });
    },

    activeItemId (): symbol | undefined {
      return this.activeItem?.id ?? undefined;
    },

    activeItemName (): string {
      return this.activeItem?.name ?? '';
    },
  },

  methods: {
    NOOP,

    openActionSheet () {
      this.isOpenActionSheet = true;
      PageScroll.disable();
    },

    closeActionSheet () {
      this.isOpenActionSheet = false;
      PageScroll.enable();
    },

    onClickItem (value: string): void {
      this.$emit('change-active', value);
    },
  },
});
