





























import Vue from 'vue';

import ArticleCardContainer from '~/containers/ArticleCardContainer/ArticleCardContainer.vue';
import ArchivePageTitle from '~/components/ArchivePageTitle.vue';
import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
import TypeSwitch from '~/components/TypeSwitch/TypeSwitch.vue';
import { isTopPage, isHomePage, isArchivePage, isListAllPage, isHomeHealth, isHealthPath } from '~/router';
import CategoriesList from '~/components/CategoriesList.vue';

import { topTypeList } from '~/data/top';
import { getFullUrl } from '~/utils';

export default Vue.extend({
  name: 'ArchivePage',

  components: {
    TypeSwitch,
    ArticleCardContainer,
    ArchivePageTitle,
    WidgetsRendererContainer,
    CategoriesList,
  },

  layout (context) {
    return isHealthPath(context.route.path) ? 'health' : 'default';
  },

  middleware: ['removeAbTitles', 'fetchArchivePageData'],

  topTypeList,
  computed: {
    articles (): Array<IArticle> {
      return this.$store.getters.currentArticles;
    },

    breadcrumbs (): Record<string, string> {
      return this.$store.getters.currentPageData.breadcrumb ?? [];
    },

    isHomePage (): boolean {
      return isHomePage(this.$route.name);
    },

    isTopPage (): boolean {
      return isTopPage(this.$route.name);
    },

    isArchivePage (): boolean {
      return isArchivePage(this.$route.name);
    },

    isHealthRoute (): boolean {
      return isHealthPath(this.$route.path);
    },

    isHealthHome (): boolean {
      const { name } = this.$route;
      return isHomeHealth(name);
    },

    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },

    isButtonVisible (): boolean {
      // не 120 потому что некоторые дублирующиеся статьи могут удаляться
      // например если статья пришла сначала в ?page=1, а потом в ?page=2
      return this.$store.getters.currentArticles.length >= 115;
    },
    title (): string {
      if (this.$store.getters.currentTitle) {
        return this.$store.getters.currentTitle;
      }

      // Для главной не приходит title в новом апи, хардкод для списка всех статей
      return isListAllPage(this.$route.name) ? 'Все статьи' : '';
    },
    icon (): string {
      return this.$store.getters.currentIcon;
    },
  },

  mounted () {
    this.$logger('mounted', true);
  },

  methods: {
    onClickShowMore (): void {
      this.$sendYandexMetrika({
        level1: 'Клик_Показать еще_Списочная карточка',
        level4: getFullUrl('/list/?page=5'),
        level6: this.title,
      });
    },

    getTypePage (): string {
      switch (true) {
        case this.isTopPage:
          return 'Лучшее';
        case this.isHomePage:
          return 'Главная';
        case this.isArchivePage:
          return 'Архивы';
        case this.isHealthPath:
          return 'Здоровье';
        default:
          return '';
      }
    },

    onChangeActive (path: string): void {
      location.href = path;
    },
  },
});
